import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../theme/colors';

const CustomBottomTab = ({ state, navigation }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.tabButton}
        onPress={() => navigation.navigate('HomeTab')}
      >
        <Ionicons
          name={state.index === 0 ? 'home' : 'home-outline'}
          size={24}
          color={state.index === 0 ? colors.primary : colors.gray}
        />
        <Text style={[styles.label, state.index === 0 && styles.activeLabel]}>
          Home
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.plusButton}
        onPress={() => navigation.navigate('WordContribution')}
      >
        <View style={styles.plusButtonInner}>
          <Ionicons name="add" size={32} color={colors.white} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.tabButton}
        onPress={() => navigation.navigate('Store')}
      >
        <Ionicons
          name={state.index === 1 ? 'cart' : 'cart-outline'}
          size={24}
          color={state.index === 1 ? colors.primary : colors.gray}
        />
        <Text style={[styles.label, state.index === 1 && styles.activeLabel]}>
          Store
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 80,
    backgroundColor: colors.secondary,
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingBottom: Platform.OS === 'ios' ? 20 : 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  tabButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  plusButton: {
    top: -20,
    padding: 8,
    backgroundColor: 'transparent',
  },
  plusButtonInner: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  label: {
    fontSize: 12,
    marginTop: 4,
    color: colors.gray,
  },
  activeLabel: {
    color: colors.primary,
  },
});

export default CustomBottomTab; 