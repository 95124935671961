import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Animated, Easing,  } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';

const WelcomeScreen = ({ setShowWelcome }) => {
  const [fadeAnim] = useState(new Animated.Value(0));
  const [scaleAnim] = useState(new Animated.Value(0.5));

  useEffect(() => {
    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.spring(scaleAnim, {
        toValue: 1,
        friction: 4,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);
    
    useEffect(() => {
    logEvent('welcome_screen_view', {});
  }, []);

  return (
    <LinearGradient
      colors={[colors.secondary, colors.primary]}
      style={styles.welcomeContainer}
    >
      <Animated.View style={[
        styles.welcomeContent,
        {
          opacity: fadeAnim,
          transform: [{ scale: scaleAnim }],
        },
      ]}>
        <Image
          source={require('../../assets/dark-logo.png')}
          style={styles.logo}
          resizeMode="cover"
        />
        <View style={styles.welcomeTextContainer}>
          <Text style={styles.arabicTitle}>أهلاً وسهلا</Text>
          <Text style={styles.title}>Welcome to KuwaitiWords</Text>
<Text style={styles.description}>
  We're so glad you're here! Let's explore Kuwaiti Arabic together, one word at a time. 
  It’s not just about language, it’s about connecting with people and culture in a fun, meaningful way.
</Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            logEvent('welcome_start_pressed', {});
            setShowWelcome(false);
          }}
        >
          <Text style={styles.buttonText}>Yalla, let's start!</Text>
        </TouchableOpacity>
        </View>
      </Animated.View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  welcomeContainer: {
    flex: 1,
  },
  welcomeContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 20,
  },
  logo: {
    width: 300,
    height: 300,
    marginTop: -50,
  },
  welcomeTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -100,
  },
  arabicTitle: {
    fontFamily: 'KhebratMusamim',
    fontSize: 55,
    fontWeight: 'bold',
    color: colors.white,
    marginBottom: 10,
  },
  title: {
    fontFamily: 'KhebratMusamim',
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.white,
    // marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    fontFamily: 'DubaiBold',
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 20,
    maxWidth: 600,
  },
  button: {
    backgroundColor: colors.white,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 25,
    elevation: 5,
  },
  buttonText: {
    fontFamily: 'KhebratMusamim',
    color: colors.primary,
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default WelcomeScreen;