import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, SafeAreaView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../theme/colors';
import { supabase } from '../utils/initSupabase';
import Toast from 'react-native-toast-message';
import { logEvent } from '../utils/firebase';

const StoreScreen = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const products = [
    {
      id: 1,
      text: 'Interactive Courses',
      description: 'Structured lessons to learn Kuwaiti Arabic',
      emoji: '📚'
    },
    {
      id: 2,
      text: 'Flashcards',
      description: 'Digital flashcards for vocabulary practice',
      emoji: '🎴'
    },
    {
      id: 3,
      text: 'Audio Lessons',
      description: 'Learn pronunciation from native speakers',
      emoji: '🎧'
    },
    {
      id: 4,
      text: 'Kuwaiti Arabic Made Easy',
      description: 'Comprehensive textbook with audio companion',
      emoji: '📚'
    },
    {
      id: 5,
      text: 'Cultural Content',
      description: 'Learn about Kuwaiti culture and traditions',
      emoji: '🕌'
    },
  ];

  const handleProductToggle = (productId) => {
    setSelectedProducts(prev => {
      if (prev.includes(productId)) {
        return prev.filter(id => id !== productId);
      }
      return [...prev, productId];
    });
  };

  const handleSubmit = async () => {
    try {
      // Get the selected product details
      const selectedProductDetails = products.filter(product => 
        selectedProducts.includes(product.id)
      );

      logEvent('store_preferences_submit', {
        selected_products: selectedProducts,
        selected_product_names: selectedProductDetails.map(p => p.text),
        products_count: selectedProducts.length
      });

      Toast.show({
        type: 'success',
        text1: 'Thank you for your feedback!',
        text2: "We'll notify you when these products become available.",
        position: 'bottom'
      });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Failed to save your preferences. Please try again.',
        position: 'bottom'
      });
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Future Products</Text>
        <Text style={styles.subtitle}>
          Help us prioritize our upcoming features by selecting the products you're most interested in!
        </Text>
      </View>

      <ScrollView contentContainerStyle={styles.scrollContent}>
        {products.map((product) => (
          <TouchableOpacity
            key={product.id}
            style={[
              styles.optionButton,
              selectedProducts.includes(product.id) && styles.optionButtonSelected
            ]}
            onPress={() => handleProductToggle(product.id)}
          >
            <Text style={styles.optionEmoji}>{product.emoji}</Text>
            <View style={styles.optionTextContainer}>
              <Text style={[
                styles.optionText,
                selectedProducts.includes(product.id) && styles.optionTextSelected
              ]}>
                {product.text}
              </Text>
              <Text style={[
                styles.optionDescription,
                selectedProducts.includes(product.id) && styles.optionTextSelected
              ]}>
                {product.description}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>

      <TouchableOpacity 
        style={[
          styles.submitButton,
          selectedProducts.length === 0 && styles.submitButtonDisabled
        ]}
        onPress={handleSubmit}
        disabled={selectedProducts.length === 0}
      >
        <Text style={styles.buttonText}>Submit Preferences</Text>
      </TouchableOpacity>
      <Toast />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.secondary,
  },
  header: {
    padding: 20,
    backgroundColor: colors.secondary,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.text.primary,
    marginBottom: 10,
    fontFamily: 'KhebratMusamim',
  },
  subtitle: {
    fontSize: 16,
    color: colors.gray,
    marginBottom: 20,
    fontFamily: 'KhebratMusamim',
  },
  scrollContent: {
    padding: 20,
  },
  optionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 12,
    padding: 15,
    marginBottom: 10,
    borderWidth: 2,
    borderColor: colors.primary,
    borderBottomWidth: 4,
  },
  optionButtonSelected: {
    backgroundColor: colors.primary,
  },
  optionEmoji: {
    fontSize: 24,
    marginRight: 15,
  },
  optionTextContainer: {
    flex: 1,
  },
  optionText: {
    fontSize: 18,
    color: colors.black,
    fontWeight: 'bold',
    fontFamily: 'KhebratMusamim',
  },
  optionDescription: {
    fontSize: 14,
    color: colors.gray,
    marginTop: 4,
    fontFamily: 'KhebratMusamim',
  },
  optionTextSelected: {
    color: colors.white,
  },
  submitButton: {
    backgroundColor: colors.primary,
    paddingVertical: 15,
    borderRadius: 12,
    margin: 20,
  },
  submitButtonDisabled: {
    backgroundColor: '#D3D3D3',
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'KhebratMusamim',
  },
});

export default StoreScreen; 