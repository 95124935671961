import React, { useState, useRef, useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, Animated, Image, useWindowDimensions, Alert, Platform, Modal, FlatList } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import FlipCard from 'react-native-flip-card';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';
import { usePackData } from '../hooks/usePackData';
import { Audio } from 'expo-av';

const { height } = Dimensions.get('window');

// Import the custom tap icon
const tapIcon = require('../../assets/tap-icon.png');

const ONBOARDING_SHOWN_KEY = 'onboardingShown';

// Define the contributors with Kuwaiti characters using Ionicons
const CONTRIBUTORS = [
  {
    id: '1',
    name: 'Fatima',
    contributions: 156,
    rating: 4.9,
    specialty: 'Classical Arabic',
    avatar: 'person-circle',
    verified: true,
  },
  {
    id: '2',
    name: 'Abdullah',
    contributions: 89,
    rating: 4.7,
    specialty: 'Kuwaiti Dialect',
    avatar: 'person',
    verified: true,
  },
  {
    id: '3',
    name: 'Sarah',
    contributions: 234,
    rating: 4.8,
    specialty: 'Modern Standard Arabic',
    avatar: 'person-circle',
    verified: true,
  },
  // ... more contributors
];

const LearningScreen = ({ route }) => {
  const { packId } = route.params;
  const { pack, isLoading, error } = usePackData(packId);
  const [activeIndex, setActiveIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState({});
  const [showOnboarding, setShowOnboarding] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const bounceAnim = useRef(new Animated.Value(0)).current;
  const carouselRef = useRef(null);
  const [isAudioModalVisible, setIsAudioModalVisible] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const avatarAnimations = useRef(
    CONTRIBUTORS.map(() => new Animated.Value(1))
  ).current;
  const [sound, setSound] = useState();

  const { width: screenWidth } = useWindowDimensions();
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (pack) {
      logEvent('screen_view', { screen_name: 'Learning', pack_id: pack.id, pack_title: pack.title });
      checkOnboardingStatus();
    }
  }, [pack]);

  const checkOnboardingStatus = async () => {
    try {
      const onboardingShown = await AsyncStorage.getItem(ONBOARDING_SHOWN_KEY);
      if (onboardingShown !== 'true') {
        setShowOnboarding(true);
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
    }
  };

  useEffect(() => {
    if (showOnboarding) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(bounceAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
          }),
          Animated.timing(bounceAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
          }),
        ])
      ).start();
    }
  }, [showOnboarding]);

  const toggleFlip = useCallback((cardIndex) => {
    if (pack) {
      logEvent('card_flipped', { pack_id: pack.id, card_index: cardIndex });
      setFlippedCards(prev => ({
        ...prev,
        [cardIndex]: !prev[cardIndex]
      }));
    }
  }, [pack]);

  const handlePreviousCard = useCallback(() => {
    if (pack && carouselRef.current) {
      logEvent('previous_card', { pack_id: pack.id, current_card_index: activeIndex });
      const previousIndex = (activeIndex - 1 + pack.items.length) % pack.items.length;
      carouselRef.current.snapToItem(previousIndex);
    }
  }, [activeIndex, pack]);

  const handleNextCard = useCallback(() => {
    if (pack && carouselRef.current) {
      logEvent('next_card', { pack_id: pack.id, current_card_index: activeIndex });
      const nextIndex = (activeIndex + 1) % pack.items.length;
      carouselRef.current.snapToItem(nextIndex);
    }
  }, [activeIndex, pack]);

  const handleOnboardingPress = async () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(async () => {
      setShowOnboarding(false);
      try {
        await AsyncStorage.setItem(ONBOARDING_SHOWN_KEY, 'true');
      } catch (error) {
        console.error('Error saving onboarding status:', error);
      }
    });
  };

  const bounceInterpolation = bounceAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 20],
  });

  const handleSnapToItem = useCallback((index) => {
    if (pack) {
      logEvent('card_changed', { pack_id: pack.id, card_index: index });
      setActiveIndex(index);
    }
  }, [pack]);

  const renderCard = useCallback(({ item, index }) => (
    <FlipCard
      flipHorizontal={true}
      flipVertical={false}
      flip={flippedCards[index] || false}
      clickable={true}
      onFlipEnd={(isFlipped) => {
        if (pack) {
          logEvent('card_flip_end', { pack_id: pack.id, card_index: index, is_flipped: isFlipped });
        }
      }}
    >
      {/* Face Side */}
      <TouchableOpacity 
        activeOpacity={1} 
        style={[styles.card, screenWidth > 900 && styles.wideCard]}
        onPress={() => toggleFlip(index)}
      >
        <Text style={styles.cardText}>{item.arabic}</Text>
        <Text style={styles.cardSubText}>{item.transliteration}</Text>
        <Text style={styles.cardSubText}>{item.translation}</Text>
      </TouchableOpacity>
      {/* Back Side */}
      <TouchableOpacity 
        activeOpacity={1} 
        style={[styles.card, screenWidth > 900 && styles.wideCard]}
        onPress={() => toggleFlip(index)}
      >
        <Text style={styles.cardInfoText}>{item.info}</Text>
      </TouchableOpacity>
    </FlipCard>
  ), [flippedCards, screenWidth, toggleFlip, pack]);

  const handleAudioPress = useCallback(() => {
    if (pack) {
      logEvent('audio_button_pressed', { pack_id: pack.id, card_index: activeIndex });
      setIsAudioModalVisible(true);
    }
  }, [pack, activeIndex]);

  const handleAvatarPress = useCallback((avatar, index) => {
    setSelectedAvatar(avatar);
    
    // Animate the selected avatar
    Animated.sequence([
      Animated.timing(avatarAnimations[index], {
        toValue: 0.8,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(avatarAnimations[index], {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start();

    // Culturally relevant feedback messages
    const messages = {
      'Um Rashid': 'حياكم الله! Um Rashid will teach you with warmth and wisdom',
      'Bu Salem': 'يا هلا! Bu Salem shares stories from Kuwait\'s pearl diving days',
      'Noor': 'مرحبا! Noor brings a modern touch to traditional learning',
      'Mishary': 'السلام عليكم! Mishary makes learning fun and memorable'
    };

    if (Platform.OS === 'web') {
      alert(messages[avatar.name]);
    } else {
      Alert.alert('Voice Selected', messages[avatar.name]);
    }
    
    setTimeout(() => {
      setIsAudioModalVisible(false);
      setSelectedAvatar(null);
    }, 2000);
  }, []);

  const handleContributorPress = useCallback(async (contributor) => {
    try {
      // Unload any previous sound
      if (sound) {
        await sound.unloadAsync();
      }

      // Create and load the sound
      const { sound: newSound } = await Audio.Sound.createAsync(
        require('../../assets/audio.mp3'), // Make sure to add your audio file
        { shouldPlay: true }
      );
      
      setSound(newSound);

      // Play the sound
      await newSound.playAsync();

      // Log the event
      if (pack) {
        logEvent('contributor_voice_played', { 
          pack_id: pack.id, 
          contributor_id: contributor.id,
          contributor_name: contributor.name 
        });
      }
    } catch (error) {
      console.error('Error playing sound:', error);
      // Fallback to alert if sound fails
      if (Platform.OS === 'web') {
        alert('Unable to play audio sample');
      } else {
        Alert.alert('Error', 'Unable to play audio sample');
      }
    }
  }, [sound, pack]);

  const renderContributor = useCallback(({ item }) => (
    <TouchableOpacity
      style={styles.contributorItem}
      onPress={() => handleContributorPress(item)}
    >
      <View style={styles.contributorLeft}>
        <View style={styles.avatarCircle}>
          <Ionicons name={item.avatar} size={30} color={colors.primary} />
        </View>
        <View style={styles.contributorInfo}>
          <View style={styles.nameRow}>
            <Text style={styles.contributorName}>{item.name}</Text>
            {item.verified && (
              <Ionicons name="checkmark-circle" size={16} color={colors.primary} />
            )}
          </View>
          <Text style={styles.contributorSpecialty}>{item.specialty}</Text>
        </View>
      </View>
      <View style={styles.contributorStats}>
        <Text style={styles.contributionCount}>{item.contributions} contributions</Text>
        {/* <View style={styles.ratingContainer}>
          <Ionicons name="star" size={14} color={colors.primary} />
          <Text style={styles.ratingText}>{item.rating}</Text>
        </View> */}
      </View>
    </TouchableOpacity>
  ), []);

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  if (error || !pack) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Error loading pack data. Please try again.</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Carousel
        ref={carouselRef}
        data={pack.items}
        renderItem={renderCard}
        sliderWidth={screenWidth}
        itemWidth={screenWidth - 60}
        onSnapToItem={handleSnapToItem}
        firstItem={activeIndex}
        inactiveSlideScale={0.9}
        inactiveSlideOpacity={0.7}
        loop={true}
        useScrollView={false}
        enableMomentum={true}
        decelerationRate={0.9}
        enableSnap={true}
        snapToAlignment="center"
      />
      <View style={[
        styles.buttonContainer,
        isWeb && styles.webButtonContainer,
        isWeb && { width: Math.min(600, screenWidth) }
      ]}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.button}
          onPress={handlePreviousCard}
        >
          <Ionicons name="arrow-back-outline" size={24} color="#fff" />
          <Text style={styles.buttonText}>Previous</Text>
        </TouchableOpacity>
        
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.audioButton}
          onPress={handleAudioPress}
        >
          <Ionicons name="volume-high-outline" size={24} color="#fff" />
        </TouchableOpacity>

        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.button}
          onPress={handleNextCard}
        >
          <Text style={styles.buttonText}>Next</Text>
          <Ionicons name="arrow-forward-outline" size={24} color="#fff" />
        </TouchableOpacity>
      </View>
      {showOnboarding && (
        <Animated.View style={[styles.onboardingOverlay, { opacity: fadeAnim }]}>
          <TouchableOpacity style={styles.onboardingContent} onPress={handleOnboardingPress}>
            <Animated.Image 
              source={tapIcon} 
              style={[
                styles.tapIcon,
                {
                  transform: [{ translateY: bounceInterpolation }]
                }
              ]} 
              resizeMode="contain"
            />
            <Text style={styles.onboardingText}>Tap the card to flip it!</Text>
          </TouchableOpacity>
        </Animated.View>
      )}
      <Modal
        visible={isAudioModalVisible}
        animationType="fade"
        transparent={true}
        onRequestClose={() => setIsAudioModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPress={() => setIsAudioModalVisible(false)}
        >
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <View style={styles.communityHeader}>
                <Ionicons name="people" size={24} color={colors.primary} />
                <Text style={styles.modalTitle}>Community Contributors</Text>
              </View>
              {/* <TouchableOpacity 
                onPress={() => setIsAudioModalVisible(false)}
                style={styles.closeButton}
              >
                <Ionicons name="close" size={24} color={colors.primary} />
              </TouchableOpacity> */}
            </View>
            
            <FlatList
              data={CONTRIBUTORS}
              renderItem={renderContributor}
              keyExtractor={(item) => item.id}
              contentContainerStyle={styles.contributorList}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.secondary,
    paddingBottom: 20,
  },
  card: {
    height: height * 0.6,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.secondary,
    justifyContent: 'center',
    backgroundColor: colors.primary,
    marginTop: 20,
    padding: 20,
  },
  wideCard: {
    width: 400,
    alignSelf: 'center',
  },
  cardText: {
    fontFamily: 'AlHurraBold',
    textAlign: 'center',
    fontSize: 60,
    lineHeight: 100,
    backgroundColor: 'transparent',
  },
  cardSubText: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'KhebratMusamim',
    backgroundColor: 'transparent',
    marginTop: 10,
  },
  cardInfoText: {
    textAlign: 'center',
    fontSize: 24,
    backgroundColor: 'transparent',
    color: colors.secondary,
    fontFamily: 'KhebratMusamim',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingHorizontal: 20,
  },
  webButtonContainer: {
    position: 'relative',
    alignSelf: 'center',
    marginTop: 20,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    width: 120,
  },
  audioButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    width: 50,
    height: 50,
  },
  buttonText: {
    fontSize: 18,
    color: '#fff',
    marginLeft: 5,
    fontFamily: 'KhebratMusamim',
  },
  onboardingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  onboardingContent: {
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  onboardingText: {
    color: '#fff',
    fontSize: 18,
    marginTop: 20,
    textAlign: 'center',
  },
  tapIcon: {
    width: 50,
    height: 50,
    tintColor: '#fff',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondary,
  },
  loadingText: {
    fontSize: 18,
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondary,
    padding: 20,
  },
  errorText: {
    fontSize: 18,
    color: 'red',
    fontFamily: 'KhebratMusamim',
    textAlign: 'center',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  modalContent: {
    backgroundColor: colors.secondary,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: 20,
    maxHeight: height * 0.8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: `${colors.primary}20`,
  },
  modalTitle: {
    fontSize: 24,
    fontFamily: 'KhebratMusamim',
    color: colors.primary,
  },
  closeButton: {
    padding: 8,
    position: 'absolute',
    right: 0,
  },
  contributorList: {
    paddingHorizontal: 10,
  },
  contributorItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 12,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  contributorLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  avatarCircle: {
    width: 45,
    height: 45,
    borderRadius: 23,
    backgroundColor: `${colors.primary}15`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contributorInfo: {
    marginLeft: 12,
    flex: 1,
  },
  nameRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  contributorName: {
    fontSize: 16,
    fontFamily: 'KhebratMusamim',
    color: colors.primary,
    fontWeight: 'bold',
  },
  contributorSpecialty: {
    fontSize: 14,
    color: `${colors.primary}99`,
    marginTop: 2,
  },
  contributorStats: {
    alignItems: 'flex-end',
  },
  contributionCount: {
    fontSize: 13,
    color: colors.primary,
    fontWeight: '500',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    gap: 4,
  },
  ratingText: {
    fontSize: 13,
    color: colors.primary,
    fontWeight: '500',
  },
  communityHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    flex: 1,
    justifyContent: 'center',
  },
});

export default LearningScreen;

