import React, { useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView, Dimensions, ActivityIndicator } from 'react-native';
import HeroSection from '../components/HeroSection';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';
import { usePackData } from '../hooks/usePackData';

const { width: screenWidth } = Dimensions.get('window');

const PackDetailScreen = ({ route, navigation }) => {
  const { packId } = route.params; // Change this line to destructure packId
  const { pack, isLoading, error } = usePackData(packId); // Pass single packId

  useEffect(() => {
    if (pack) {
      logEvent('screen_view', { screen_name: 'PackDetail', pack_id: pack.id, pack_title: pack.title });
    }
  }, [pack]);

  const handleStartLearning = () => {
    if (pack) {
      logEvent('start_learning', { pack_id: pack.id, pack_title: pack.title });
      navigation.navigate('Learning', { packId: pack.id });
    }
  };

  const containerStyle = [
    styles.container,
    screenWidth > 900 && styles.wideContainer
  ];

  const descriptionStyle = [
    styles.description,
    screenWidth > 900 && styles.wideContent
  ];

  if (isLoading || !pack) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Error loading pack data. Please try again.</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={containerStyle}>
      <View style={styles.content}>
        <HeroSection title={pack.title} category={pack.category} count={pack.count} />
        <Text style={descriptionStyle}>{pack.description}</Text>
      </View>
      <TouchableOpacity activeOpacity={0.8} style={styles.button} onPress={handleStartLearning}>
        <Text style={styles.buttonText}>Start Learning</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  wideContainer: {
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
  },
  wideContent: {
    fontSize: 20,
    lineHeight: 36,
    maxWidth: 700,
    alignSelf: 'center',
  },
  description: {
    fontFamily: 'DubaiRegular',
    // fontWeight: 'bold',
    letterSpacing: 0.2,
    fontSize: 18,
    padding: 20,
    lineHeight: 24,
  },
  button: {
    backgroundColor: colors.primary,
    padding: 15,
    borderRadius: 8,
    margin: 20,
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'KhebratMusamim',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 20,
  },
  errorText: {
    fontSize: 18,
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
    textAlign: 'center',
  },
});

export default PackDetailScreen;